<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Tabulator - Developer Documentation" />
    <!--EOC-->
    <v-row>
      <v-col cols="12" sm="3">
        <TheLeftSidebar :leftMenuItems="leftmenu"></TheLeftSidebar>
      </v-col>

      <v-col cols="12" sm="9">
        <v-card min-height="70vh" rounded="lg">
          <v-card-title>Table</v-card-title>
          <v-card-text>

            <p>Example Table</p>


            <div>
            <div id="pagination-counter" style="float: left; margin-right: 2px;"></div>
            <span id="record-count" style="float: left;font-size: 14px; margin-top: 2px;"></span>
            <div style="clear: both;"></div>
          </div>

            <BreadTabulator
            ref="tabulatorDataTable"
              layout="fitColumns"
              :autoColumns="false"
              :paginationSize="this.paginationSize"
              :paginationSizeSelector="this.paginationSizeSelector"
              :apiUrl="this.apiGetBrowseUrl()"
              :columns="this.columns"
              :fiterCondition="this.getConditions()"
            ></BreadTabulator>

            <p>
              we are going to use the common component (BreadTabulator.vue) to
              create the table.
            </p>
            <p>
              1. First we have to import the component(BreadTabulator.vue) in
              the global way or else in the local way
            </p>
            <p>
              Global way - import the created component inside the main.js file.
            </p>
            <CopyToClipBoard :text-to-copy=scriptSectionGlobalImport></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>
                
                    {{ scriptSectionGlobalImport }}
                  </pre
              >
            </VueCodeHighlight>

            <p>Local way - import the created component inside the vue file</p>
            <CopyToClipBoard :text-to-copy=scriptSectionLocalImport></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                
                    {{ scriptSectionLocalImport }}
                  </pre
              >
            </VueCodeHighlight>
            <p>Assign it under the component section (Localy assigning)</p>
            <CopyToClipBoard :text-to-copy=scriptSectionLocalAssign></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
               
                    {{ scriptSectionLocalAssign }}
                  </pre
              >
            </VueCodeHighlight>
            <p>2. Call the common component inside the template section</p>
            <CopyToClipBoard :text-to-copy=templateSection></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                
                    {{ templateSection }}
                  </pre
              >
            </VueCodeHighlight>
            <p>3. pass required data to component </p>

            <p>passing columns and other data</p>
            <CopyToClipBoard :text-to-copy=scriptSectionData></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                
                    {{ scriptSectionData }}
                  </pre
              >
            </VueCodeHighlight>

            <p>pass the api url to the component</p>
            <CopyToClipBoard :text-to-copy=scriptSectionApiUrl></CopyToClipBoard>
            <VueCodeHighlight>
              <pre>
                
                    {{ scriptSectionApiUrl }}
                  </pre
              >
            </VueCodeHighlight>

            
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
import TheLeftSidebar from "../components/PageDeveloperTabulator/TheLeftSidebar.vue";
import leftmenu from "../config/leftmenu.js";
import Service from "@/objects/service";
import CopyToClipBoard from "../components/PageDeveloperTabulator/CopyToClipBoard.vue"; 
import { component as VueCodeHighlight } from 'vue-code-highlight';
import "vue-code-highlight/themes/prism-okaidia.css";
/* import "vue-code-highlight/themes/window.css"; */
//BOC:[model]
import Model from "@/objects/model";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    TheLeftSidebar,
    CopyToClipBoard,
    VueCodeHighlight,
  },
  computed: mapState({
    //
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    leftmenu: leftmenu,

    //BOC:[service]
    service: new Service(),
    //EOC

    //BOC:[model]
    model: new Model(),
    //EOC

    scriptSectionGlobalImport: `
    Vue.component('BreadTabulator', require('./components/common/BreadTabulator.vue').default);`,
    scriptSectionLocalImport: `
    import BreadTabulator from "@/components/common/BreadTabulator.vue";`,
    templateSection: `
    <BreadTabulator
    ref="tabulatorDataTable"
      layout="fitColumns"
      :autoColumns=false
      :paginationSize=this.paginationSize
      :paginationSizeSelector=this.paginationSizeSelector
      :apiUrl=this.apiGetBrowseUrl()
      :columns=this.columns
      :fiterCondition = this.getConditions()
    ></BreadTabulator>`,
    scriptSectionLocalAssign: `
    components: {
      BreadTabulator,
    },`,

    scriptSectionData: `
    paginationSizeSelector : [10, 20, 30, 40, 50],
    paginationSize: 10,
    columns : [
    { title: "ID", field: "id" },
        {
          title: "Name",
          field: "name",
        },

        {
          title: "Cost",
          field: "cost",
        },

        {
          title: "Price",
          field: "price",
        },

        {
          title:"Stock Count",
          field:"stock"
        },

        {
          title:"Available",
          field:"instock"
        },

        {
          title:"Rating",
          field:"rating"
        },
    ],`,

    scriptSectionApiUrl: `
    apiGetBrowseUrl() {
      return "/api/developer/component/breadtabulator";
    },`,

    paginationSizeSelector: [10, 20, 30, 40, 50],
    paginationSize: 10,
    columns: [
      { title: "ID", field: "id" },
      {
        title: "Name",
        field: "name",
      },

      {
        title: "Cost",
        field: "cost",
      },

      {
        title: "Price",
        field: "price",
      },

      {
        title: "Stock Count",
        field: "stock",
      },

      {
        title: "Available",
        field: "instock",
      },

      {
        title: "Rating",
        field: "rating",
      },
    ],
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "BreadTabulator",
      to: { name: "PageDeveloperComponentBreadTabulator" },
      exact: true,
    });

    this.breadcrumb.add({
      text: "Table",
      to: { name: "" },
      exact: true,
    });
    //EOC

    //BOC:[model]
    this.model.getByKey("demoProduct");
    //EOC

    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
  },
  mounted() {
    console.log(this.model.key);
    console.log(this.$service[this.service.key]);
  },
  methods: {
    apiGetBrowseUrl() {
      return `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/list`;
    },

    getConditions() {},
  },
};
</script>

<style scoped>

</style>